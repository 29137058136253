<template>
  <div class="region-search-autocomplete" :id="wrapperId">
    <ng-autocomplete
      v-bind="$attrs"
      :value="value"
      :items="items"
      @input="onInput"
      item-text="regionData.name"
      no-filter
      :search-input.sync="searchQueryLocal"
      :loading="isLoading"
      @update:search-input="onUpdateSearchInput"
      :hide-no-data="!searchQueryLocal || isLoading"
      return-object
      style="position: relative"
    >
      <template v-slot:item="{ item }">
        <v-list-item-icon class="mr-2 ml-n1">
          <v-icon small>{{
            item.isHistory ? 'mdi-history' : itemIcons[item.regionData.type] || 'mdi-map-marker'
          }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ item.regionData.name }}
        </v-list-item-content>
        <v-list-item-action class="my-0" v-if="item.isHistory">
          <ng-button
            class="pa-4 mr-n3"
            color="grey"
            x-small
            text
            icon-name="mdi-delete"
            icon
            @click.stop="deletePreviouslySelectedRegion(item)"
          />
        </v-list-item-action>
        <!-- </div> -->
      </template>
    </ng-autocomplete>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
import * as searchService from '@/services/searchService';
import * as regionStorageService from '@/services/regionStorageService';
import { createPreviouslySelectedRegions, areRegionsEqual } from '@/utils/regionSearchUtils';

export default {
  name: 'RegionSearchAutocomplete',
  props: {
    value: {
      type: Object,
    },
    wrapperId: {
      type: String,
    },
  },
  inheritAttrs: false,
  data() {
    return {
      items: [],
      searchQueryLocal: null,
      isLoading: false,
      itemIcons: {
        city: 'mdi-city-variant',
        subregion: 'mdi-shield-half-full',
        country: 'mdi-flag',
      },
      searchAbortController: undefined,
      debouncedSearch: undefined,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        if (!newValue) {
          this.items = createPreviouslySelectedRegions(regionStorageService.getSelectedRegions());
        } else if (!this.items.some((item) => !item.isHistory && areRegionsEqual(item, newValue))) {
          // to set correct search query
          // without this line searchQuery = null, because of value doesn't match any items
          this.items = [newValue];
        }
      },
      immediate: true,
    },
    async searchQueryLocal(query) {
      if (this.searchAbortController) {
        this.searchAbortController.abort();
        this.searchAbortController = undefined;
      }

      this.isLoading = true;
      this.debouncedSearch(query);
    },
  },
  created() {
    this.debouncedSearch = debounce(this.search, 400);
  },
  methods: {
    onInput(newValue) {
      if (newValue) regionStorageService.addSelectedRegion(newValue);
      this.$emit('input', newValue);
    },
    onUpdateSearchInput(newQuery) {
      this.$emit('update:search-input', newQuery);
    },
    async search(query) {
      try {
        this.searchAbortController = new AbortController();
        // show history items only when value is empty
        // if value presents may be collision when this.items from search dont match value, but history items do
        // It would be better if history items dont affect synced localSearchQuery
        // but it will require not to use ng-autocomplete and write custom search input
        // consisting of simple input and dropdown menu
        if (!query && !this.value) {
          this.items = createPreviouslySelectedRegions(regionStorageService.getSelectedRegions());
        } else {
          this.$emit('search', query);
          this.items = await searchService.memoizedSearchRegionsByQuery(query, this.$i18n.locale, {
            signal: this.searchAbortController.signal,
          });
        }
      } catch (e) {
        if (e.name === 'AbortError') return;
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    deletePreviouslySelectedRegion(item) {
      regionStorageService.deleteSelectedRegion(item);
      this.items = createPreviouslySelectedRegions(regionStorageService.getSelectedRegions());
    },
  },
};
</script>

<style lang="scss" scoped>
.region-search-autocomplete {
  position: relative;
}
</style>
