import { getProductPriceObject } from '@/utils/productUtils';
import httpClient from './httpClient';

const getProductPrice = ({ product, locale, options }) =>
  httpClient
    .post('api/product_price/', product, {
      signal: options && options.signal,
      noErrorNotification: (options && options.noErrorNotification) || false,
    })
    .then((response) => {
      if (response) {
        const { price_origin, price_new, discount, coupon_status } = response.data.data;
        const productPriceResult = {
          ...getProductPriceObject({ lang: locale, price_origin, price_new, discount }),
          couponStatus: coupon_status,
        };
        return productPriceResult;
      }
      return null;
    });

export { getProductPrice };
