<template>
  <div
    class="ng-price"
    :class="{ 'ng-price--simple': simple, 'ng-price--crossed': isCrossed }"
  >
    <template v-if="currencyComp.position === 'first'">
      <span
        :class="[
          'ng-price__currency',
          `ng-price__currency--${currency.toLowerCase()}`,
          currencyClass,
        ]"
        v-html="currencyComp.symbol"
      ></span
      ><span :class="['ng-price__amount', amountClass]">{{
        amountFormatted
      }}</span
      ><span :class="['ng-price__suffix', suffixClass]" v-if="monthly || yearly"
        ><slot name="suffix">{{ suffix }} </slot></span
      >
    </template>

    <template v-if="currencyComp.position === 'last'">
      <span :class="['ng-price__amount', amountClass]">{{
        amountFormatted
      }}</span
      >&nbsp;<span
        :class="[
          'ng-price__currency',
          `ng-price__currency--${currency.toLowerCase()}`,
          currencyClass,
        ]"
        v-html="currencyComp.symbol"
      ></span
      ><span :class="['ng-price__suffix', suffixClass]" v-if="monthly || yearly"
        ><slot name="suffix">{{ suffix }} </slot></span
      >
    </template>
  </div>
</template>

<i18n>
{
  "en": {
    "per_month": "/mo",
    "per_year": "/year"
  },
  "ru": {
    "per_month": "/мес.",
    "per_year": "/год"
  }
}
</i18n>

<script>
import { formatNumber } from '../../../js/utilities';

export default {
  name: 'NgPrice',
  props: {
    amount: { type: [Number, String] },
    currency: { type: String },
    monthly: { type: Boolean },
    yearly: { type: Boolean },
    simple: { type: Boolean, default: false },
    amountClass: { type: String },
    currencyClass: { type: String },
    suffixClass: { type: String },
    isFormatted: { type: Boolean, default: false },
    isCrossed: { type: Boolean, default: false },
  },
  data() {
    return {
      currencies: {
        rub: {
          symbol: '₽',
          position: 'last',
        },
        usd: {
          symbol: '$',
          position: 'first',
        },
        eur: {
          symbol: '€',
          position: 'first',
        },
      },
    };
  },
  computed: {
    suffix() {
      if (this.monthly) return this.$t('per_month');
      if (this.yearly) return this.$t('per_year');
      return '';
    },
    currencyComp() {
      return this.currencies[this.currency.toLowerCase()];
    },
    amountFormatted() {
      return this.isFormatted ? formatNumber(this.amount) : this.amount;
    },
  },
};
</script>

<style lang="scss" scoped>
.ng-price {
  white-space: nowrap;

  &__currency--eur {
    margin-right: 0.05em;
  }

  &:not(.ng-price--simple) {
    font-family: $heading-font-family;

    .ng-price__amount {
      line-height: 1.2;
    }

    .ng-price__currency--rub {
      font-size: 0.8em;
      margin-left: -0.1em;
    }

    .ng-price__suffix {
      font-size: 0.8em;
    }
  }

  &.ng-price--crossed {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: tomato;
      left: 0;
      top: 47%;
      transform: rotate(13deg);
    }
  }
}
</style>
