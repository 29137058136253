<template>
  <div class="ng-input" v-show="$attrs.type !== 'hidden'">
    <ng-label
      class="ng-text-field__label mb-1"
      v-if="!$attrs.floatedLabel && $attrs.label"
      :text="$attrs.label"
      :error="$attrs.error"
      :required="$attrs.required"
    >
      {{ $attrs.label }}
    </ng-label>
    <slot :input-attrs="innerAttrs"></slot>
  </div>
</template>

<script>
import {
  getDefaultInputAttrs,
  prepareInputAttrs,
} from '../../enhancers/useInput';
import NgLabel from './NgLabel.vue';

export default {
  components: { NgLabel },
  inheritAttrs: false,
  computed: {
    defaultAttrs() {
      return getDefaultInputAttrs(this.$attrs);
    },
    innerAttrs() {
      return prepareInputAttrs(this.$attrs, this.defaultAttrs);
    },
  },
};
</script>

<style lang="scss" scoped></style>
