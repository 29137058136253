import { areRegionsEqual } from '@/utils/regionSearchUtils';

const SELECTED_REGIONS_MAX_LENGTH = 5;

const getSelectedRegions = () => {
  const selectedRegions = localStorage.getItem('selectedRegions');
  return selectedRegions ? JSON.parse(selectedRegions) : [];
};

const addSelectedRegion = (selectedRegion) => {
  const selectedRegions = getSelectedRegions();
  const selectedRegionIndex = selectedRegions.findIndex((region) => areRegionsEqual(region, selectedRegion));
  if (selectedRegionIndex === -1) {
    if (selectedRegions.length === SELECTED_REGIONS_MAX_LENGTH) {
      selectedRegions.pop();
    }
    localStorage.setItem('selectedRegions', JSON.stringify([selectedRegion, ...selectedRegions]));
  } else {
    // move selectedRegion to the 0 position
    selectedRegions.splice(0, 0, selectedRegions.splice(selectedRegionIndex, 1)[0]);
    localStorage.setItem('selectedRegions', JSON.stringify(selectedRegions));
  }
};

const deleteSelectedRegion = (selectedRegion) => {
  const selectedRegions = getSelectedRegions().filter((region) => !areRegionsEqual(region, selectedRegion));
  localStorage.setItem('selectedRegions', JSON.stringify(selectedRegions));
};

export { addSelectedRegion, getSelectedRegions, deleteSelectedRegion };
