import Vue from 'vue';
import NgLabel from './components/ui/NgLabel.vue';
import NgTextField from './components/ui/NgTextField.vue';
import NgTextArea from './components/ui/NgTextArea.vue';
import NgPasswordTextField from './components/ui/NgPasswordTextField.vue';
import NgSelect from './components/ui/NgSelect.vue';
import NgAutocomplete from './components/ui/NgAutocomplete.vue';
import NgCheckbox from './components/ui/NgCheckbox/NgCheckbox.vue';
import NgDatePicker from './components/ui/NgDatePicker.vue';
import NgCardGroup from './components/ui/NgCardGroup.vue';
import NgFieldset from './components/ui/NgFieldset/NgFieldset.vue';

Vue.component('ng-label', NgLabel);
Vue.component('ng-text-field', NgTextField);
Vue.component('ng-text-area', NgTextArea);
Vue.component('ng-password-text-field', NgPasswordTextField);
Vue.component('ng-select', NgSelect);
Vue.component('ng-autocomplete', NgAutocomplete);
Vue.component('ng-checkbox', NgCheckbox);
Vue.component('ng-date-picker', NgDatePicker);
Vue.component('ng-card-group', NgCardGroup);
Vue.component('ng-fieldset', NgFieldset);
Vue.component('ng-phone-input', () =>
  import(/* webpackPrefetch: true */ './components/ui/NgPhoneInput.vue'),
);
