<template>
  <span class="self-link__container">
    <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
    <a class="anchor" :id="href"></a>
    <a class="self-link" :href="`#${href}`">
      <v-icon class="self-link__icon">mdi-link</v-icon>
      <slot></slot>
    </a>
  </span>
</template>

<script>
export default {
  props: {
    href: String,
    text: String,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.self-link,
.self-link:hover,
.self-link:active,
.self-link:focus,
.v-application .self-link,
.v-application .self-link:hover,
.v-application .self-link:active,
.v-application .self-link:focus {
  position: relative;
  color: inherit;
  text-decoration: none;
  border: 0;
  color: currentColor;
}

.self-link {
  &__icon,
  &__icon.v-icon {
    display: none;
    font-size: 18px;
    position: absolute;
    left: -24px;
    top: 50%;
    margin-top: -9px;
  }

  &:hover {
    .self-link__icon {
      display: inline-flex;
      color: $gray;
    }
  }
}
</style>
