import Vue from 'vue';
import NgButton from './components/ui/NgButton/NgButton.vue';
import NgSheet from './components/ui/NgSheet/NgSheet.vue';
import NgPrice from './components/ui/NgPrice/NgPrice.vue';
import NgTabs from './components/ui/NgTabs/NgTabs.vue';
import NgList from './components/ui/NgList/NgList.vue';
import SelfLink from './components/ui/SelfLink/SelfLink.vue';

Vue.component('ng-button', NgButton);
Vue.component('ng-sheet', NgSheet);
Vue.component('ng-price', NgPrice);
Vue.component('ng-tabs', NgTabs);
Vue.component('ng-list', NgList);
Vue.component('self-link', SelfLink);
