import { getProductSettingsByKeys } from '@/services/product/settings/productSettings';
import { formatRegionCode } from '@/utils/regionUtils';
import i18n from '@/i18n';

const sanitizeNextProductQuery = ({ regionCode, nextProductQuery, lang }) => {
  const regionCodeLowerCase = regionCode.toLowerCase();
  const nextProductSettings = getProductSettingsByKeys({ keys: [nextProductQuery], i18n })[0];
  const shouldShowWorldwideProducts =
    regionCodeLowerCase !== 'ru' &&
    !regionCodeLowerCase.includes('ru-') &&
    !(regionCodeLowerCase === 'custom' && lang === 'ru');

  if (nextProductSettings) {
    return shouldShowWorldwideProducts && !nextProductSettings.isWorldwide ? 'base' : nextProductQuery;
  }
  return 'base';
};

const getProductRoute = ({ regionCode, productName, queryParams = {} }) => {
  if (['base', 'dem', 'msbld', 'msrd'].includes(productName))
    return {
      name: productName,
      params: {
        regionCode,
      },
      query: queryParams,
    };
  if (['gkh', 'sat', 'oopt', 'rnlic', 'overture'].includes(productName))
    return {
      name: 'simpleDataProduct',
      params: {
        regionCode,
        productId: productName,
      },
      query: queryParams,
    };
  return {
    name: 'base',
    params: {
      regionCode,
    },
    query: queryParams,
  };
};

const getNextProductRoute = ({ regionCode, route, query = {} }) => {
  const { 'next-product': nextProductQuery } = route.query || {};

  const nextProductName = sanitizeNextProductQuery({
    regionCode,
    nextProductQuery,
    lang: route.params.lang,
  });

  return getProductRoute({
    regionCode: formatRegionCode(regionCode),
    productName: nextProductName,
    queryParams: { ...query },
  });
};

const getProductNameFromRoute = (route) => {
  let productName = ['base', 'dem', 'msbld', 'msrd', 'simpleDataProduct'].includes(route.name) ? route.name : 'base';
  if (productName === 'simpleDataProduct') productName = route.params.productId;
  return productName;
};

export { getProductRoute, getProductNameFromRoute, sanitizeNextProductQuery, getNextProductRoute };
