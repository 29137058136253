import i18n from '@/i18n';
import { getRegionalProductSettings, getProductSettingsByKeys } from '@/services/product/settings/productSettings';
import { addTimestampToUrl } from '@/utils/urlUtils';

const getProductName = (key) => {
  const productSettings = getProductSettingsByKeys({ keys: [key], i18n })[0];
  return productSettings && productSettings.name;
};

const extendProductsByRouteParams = ({ products, regionCode, route }) =>
  products.map((productObject) => {
    return {
      ...productObject,
      to: {
        ...productObject.to,
        params: {
          ...productObject.to.params,
          regionCode,
        },
        query: route.query,
        hash: route.hash,
      },
      value: productObject.key,
    };
  });

const createLayerStr = (number) => `<nobr>${number} ${i18n.tc('product.layer', number)}</nobr>`;

const createSummaryStr = ({ id, attrs, layerNumber }) => {
  const name = getProductName(id);
  const attrStr = `, ${attrs.map((attr) => `<nobr>${attr}</nobr>`).join(', ')}`;
  const layerStr = layerNumber ? `,<br> ${createLayerStr(layerNumber)}` : '';
  return `${name}${attrStr}${layerStr}`;
};

const createSummaryStrDetailed = ({ regionName, id, attrs, layerNumber }) => {
  const layerStr = layerNumber ? `, ${createLayerStr(layerNumber)}` : '';
  const summaryGeneral = `${regionName}, ${getProductName(id)}${layerStr}`;
  const attrStr = attrs
    .map(([key, value]) => `${key}: <span class="font-weight-medium"><nobr>${value}</nobr></span>`)
    .join('<br/>');
  return `${summaryGeneral} <br>${attrStr}`;
};

const getProductImages = (imagesSettings, productId) => {
  const lang = i18n.locale === 'ru' ? 'ru' : 'en';
  return imagesSettings.map(({ name, format }) => ({
    src: addTimestampToUrl(`${process.env.VUE_APP_PUBLIC_PATH}img/product/${name}_${lang}.${format}`),
    caption: i18n.t(`product.${productId}.imageCaptions.${name}`),
    caption_detailed: i18n.t(`product.${productId}.imageDetailedCaptions.${name}`),
  }));
};

const getProductListForTabsAndCards = () => {
  const productSettings = getRegionalProductSettings(i18n);

  return productSettings.map((product, index) => {
    const { id, serverId, name, icon } = product;
    return {
      icon,
      text: name,
      key: id,
      serverKey: serverId,
      value: index,
    };
  });
};

const getProductionTimeString = (productionTime, options) => {
  const hours = Math.floor(productionTime / 60);
  const minutes = productionTime % 60;
  const minutesMessageKey = options && options.parentCase ? 'app.minutesParentCase' : 'app.minutes';
  const hoursMessageKey = options && options.parentCase ? 'app.hoursParentCase' : 'app.hours';
  if (hours > 0 && minutes > 0)
    return `${hours} ${i18n.tc(hoursMessageKey, hours)} ${minutes} ${i18n.tc(minutesMessageKey, minutes)}`;
  if (hours > 0 && minutes === 0) return `${hours} ${i18n.tc(hoursMessageKey, hours)}`;
  return `${productionTime} ${i18n.tc(minutesMessageKey, productionTime)}`;
};

const getProductPriceObject = ({ lang, price_origin, price_new, discount }) => {
  const currency = lang === 'ru' ? 'RUB' : 'EUR';
  const productPriceObject = {
    originPrice: { amount: price_origin[currency], currency: currency.toLowerCase() },
  };
  if (price_new !== null && price_new !== undefined && typeof price_new[currency] === 'number') {
    productPriceObject.priceWithDiscount = { amount: price_new[currency], currency: currency.toLowerCase() };
  }
  if (discount) productPriceObject.discount = discount;
  return productPriceObject;
};

export {
  getProductName,
  extendProductsByRouteParams,
  createSummaryStr,
  createSummaryStrDetailed,
  getProductImages,
  getProductListForTabsAndCards,
  getProductionTimeString,
  getProductPriceObject,
};
