<template>
  <BaseLayout v-bind="$attrs">
    <template v-slot:header-action>
      <region-search-autocomplete
        v-if="hasSearch"
        attach="#header-region-search-autocomplete"
        wrapper-id="header-region-search-autocomplete"
        class="header-region-search-autocomplete"
        v-model="regionSelectedInSearch"
        @input="goToRegion"
        @update:search-input="onUpdateSearchInput"
        @search="sendEventToCounters"
        :search-query="regionSearchQuery"
        :key="$route && $route.path"
        prepend-inner-icon="mdi-magnify"
        append-icon=""
        filled
        solo
        :outlined="false"
        flat
        clearable
        :placeholder="$t('app.searchLocation')"
      />
    </template>
    <slot></slot>
  </BaseLayout>
</template>

<script>
import { goToProductFromSearch } from '@/services/searchService';
import BaseLayout from '@ngservices_front/layouts/BaseLayout.vue';
import RegionSearchAutocomplete from '@/components/app/RegionSearchAutocomplete.vue';

export default {
  components: { BaseLayout, RegionSearchAutocomplete },
  props: {
    hasSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      regionSelectedInSearch: undefined,
      regionSearchQuery: '',
    };
  },
  methods: {
    onUpdateSearchInput(newQuery) {
      this.regionSearchQuery = newQuery;
    },
    goToRegion() {
      if (this.regionSelectedInSearch) {
        goToProductFromSearch({
          regionFromSearch: this.regionSelectedInSearch,
          route: this.$route,
          router: this.$router,
        });

        this.$nextTick(() => {
          this.regionSelectedInSearch = undefined;
          this.regionSearchQuery = '';
        });
      }
    },
    sendEventToCounters(query) {
      if (window.ym) window.ym(42050389, 'reachGoal', 'app-search', { query });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-region-search-autocomplete {
  width: 200px;
  white-space: normal;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    width: 300px;
  }
}
</style>
