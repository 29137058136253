import catalogService from '@/services/catalog/catalogService';

const getObjectFromArray = (arr, key) =>
  arr && arr.length ? arr.reduce((acc, item) => ({ ...acc, [item[key]]: item }), {}) : {};

const filterByQuery = (array, query) => {
  return array.filter((item) => {
    return item.name.toLowerCase().includes(query.toLowerCase());
  });
};

const sortByName = (array) =>
  array.sort((itemA, itemB) => {
    const itemNameA = itemA.name.toLowerCase();
    const itemNameB = itemB.name.toLowerCase();
    if (itemNameA < itemNameB) return -1;
    if (itemNameA > itemNameB) return 1;
    return 0;
  });

const catalog = {
  namespaced: true,
  state: () => ({
    currentCountryCode: null,
    isLoading: {
      countries: false,
      subdivisions: false,
      cities: false,
    },
    searchQuery: {
      countries: '',
      subdivisions: '',
      cities: '',
    },
    countries: null,
    subdivisions: null,
    cities: null,
    level: undefined,
  }),
  getters: {
    countriesArr(state) {
      return state.countries ? Object.values(state.countries) : [];
    },
    countriesWithSubdivisions(state, getters) {
      return getters.countriesArr.filter((country) => country.with_regions);
    },
    countriesWithCities(state, getters) {
      return getters.countriesArr.filter((country) => country.with_cities);
    },
    currentCountry(state) {
      return state.currentCountryCode && state.countries && state.countries[state.currentCountryCode];
    },
    currentCountries(state, getters) {
      return getters.countriesArr && filterByQuery(getters.countriesArr, state.searchQuery.countries);
    },
    currentSubdivisions(state) {
      return (
        state.subdivisions &&
        state.currentCountryCode &&
        state.subdivisions[state.currentCountryCode] &&
        filterByQuery(state.subdivisions[state.currentCountryCode], state.searchQuery.subdivisions)
      );
    },
    currentCities(state) {
      return (
        state.cities &&
        state.currentCountryCode &&
        state.cities[state.currentCountryCode] &&
        filterByQuery(state.cities[state.currentCountryCode], state.searchQuery.cities)
      );
    },
  },
  mutations: {
    SET_LEVEL(state, newLevel) {
      state.level = newLevel;
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_CURRENT_COUNTRY_CODE(state, code) {
      state.currentCountryCode = code && code.toUpperCase();
    },
    SET_COUNTRY_SUBDIVISIONS(state, { items, countryCode }) {
      const oldSubdivisions = state.subdivisions || {};
      state.subdivisions = {
        ...oldSubdivisions,
        [countryCode]: items,
      };
    },
    SET_COUNTRY_CITIES(state, { items, countryCode }) {
      const oldCities = state.cities || {};
      state.cities = {
        ...oldCities,
        [countryCode]: items,
      };
    },
    SET_SEARCH_QUERY(state, searchQuery) {
      state.searchQuery = searchQuery;
    },
  },
  actions: {
    async loadCountries({ state, commit }) {
      commit('SET_IS_LOADING', { ...state.isLoading, countries: true });
      const countries = await catalogService.fetchCountries();
      const countriesSorted = sortByName(countries);
      const countriesDict = getObjectFromArray(countriesSorted, 'code');
      commit('SET_COUNTRIES', countriesDict);
      commit('SET_IS_LOADING', { ...state.isLoading, countries: false });
    },
    async loadSubdivisions({ state, commit }, { countryCode }) {
      commit('SET_IS_LOADING', { ...state.isLoading, subdivisions: true });
      const items = await catalogService.fetchSubdivisions(countryCode);
      const itemsSorted = sortByName(items);
      commit('SET_COUNTRY_SUBDIVISIONS', { items: itemsSorted, countryCode });
      commit('SET_IS_LOADING', { ...state.isLoading, subdivisions: false });
    },
    async loadCities({ state, commit }, { countryCode }) {
      commit('SET_IS_LOADING', { ...state.isLoading, cities: true });
      const items = await catalogService.fetchCities(countryCode);
      const itemsSorted = sortByName(items);
      commit('SET_COUNTRY_CITIES', { items: itemsSorted, countryCode });
      commit('SET_IS_LOADING', { ...state.isLoading, cities: false });
    },
  },
};

export default catalog;
