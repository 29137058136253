<template>
  <header class="header" :class="{ 'header--fixed': fixed }">
    <v-container class="header__container-1" pa-0 :fluid="fluid">
      <v-container class="header__container-2" py-0 :fluid="!centered">
        <div class="header__logo">
          <v-icon
            class="header__menu-btn"
            v-show="$vuetify.breakpoint.mobile"
            @click="$emit('menu-icon-click')"
          >
            mdi-menu
          </v-icon>
          <router-link v-if="config.isSPA" to="/" class="withoutripple">
            <app-logo></app-logo>
          </router-link>
          <!-- eslint-disable vuejs-accessibility/anchor-has-content -->
          <a v-else href="/" class="withoutripple">
            <app-logo></app-logo>
          </a>
          <!-- eslint-enable -->
        </div>

        <div class="header__title h1">
          <router-link v-if="config.isSPA" to="/">{{
            serviceName
          }}</router-link>
          <a v-else href="/">{{ serviceName }}</a>
        </div>

        <nav class="header-menu" v-if="!$vuetify.breakpoint.mobile">
          <slot name="header-menu">
            <app-menu
              :items="menuItems"
              :active-item="currentPage && currentPage.id"
            >
            </app-menu>
          </slot>
        </nav>

        <v-spacer></v-spacer>

        <div class="header__right">
          <slot name="action"> </slot>

          <template v-if="withAuthorization">
            <slot name="user">
              <template v-if="isAuthenticated">
                <div class="header__user" id="app-userpic">
                  <AppUser></AppUser><v-icon>mdi-menu-down</v-icon>
                </div>
                <UserMenu
                  activator="#app-userpic"
                  offset-y
                  :logout-url="customUrls && customUrls.logout"
                  :profile-url="customUrls && customUrls.profile"
                />
              </template>
              <template v-else>
                <ng-button text class="ml-1" color="primary" :href="loginUrl">
                  {{ $t('Sign in') }}
                </ng-button>
              </template>
            </slot>
          </template>
        </div>
        <!--/header__right -->
      </v-container>
    </v-container>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import { getUrlByLocale } from '../../services/UrlService';
import AppLogo from '../AppLogo/AppLogo.vue';
import AppMenu from '../AppMenu/AppMenu.vue';
import AppUser from '../AppUser/AppUser.vue';
import UserMenu from '../UserMenu/UserMenu.vue';

export default {
  name: 'AppHeader',
  props: {
    withAuthorization: {
      type: Boolean,
      default: true,
    },
    serviceName: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    customUrls: {
      type: Object,
    },
  },
  components: { AppLogo, AppMenu, AppUser, UserMenu },
  data() {
    return {};
  },
  computed: {
    ...mapState('baseApp', ['config', 'currentPage']),
    ...mapState('baseUser', ['isAuthenticated']),
    loginUrl() {
      const currentUrl = this.$router
        ? `${this.$router.options.base.slice(0, -1)}${this.$route.fullPath}`
        : window.location.pathname;
      const loginUrl =
        (this.customUrls && this.customUrls.login) ||
        getUrlByLocale('login', this.$i18n.locale);
      return this.withAuthorization ? `${loginUrl}/?next=${currentUrl}` : '#';
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  height: $header-height-sm;
  min-height: $header-height-sm;
  z-index: 40;
  white-space: nowrap;
  backface-visibility: hidden;
  font-weight: 400;
  color: $text-base;
  font-size: 14px;
  width: 100%;

  &--fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
  }

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    height: $header-height;
  }

  &__container-1,
  &__container-2 {
    display: flex;
    align-items: center;
    height: 100%;

    &.container--fluid {
      width: 100%;
    }
  }

  &__container-1 {
    background-color: $ng-light-blue;
    border-bottom: 1px solid #d3e3f2;
  }

  h1,
  .h1 {
    display: inline-block;
    font-size: map-get($font-size, 'base');
    margin: 0;
    color: var(--v-primary-base);
    font-weight: 500;

    a,
    a:hover,
    a:focus,
    a:active {
      color: $link-hover-color;
    }
  }

  a {
    border-bottom: 0;
    text-decoration: none;
  }

  &__menu-btn {
    margin-left: -6px;
    margin-right: 6px;

    &.theme--light.v-icon {
      font-size: 24px;
      color: $dark-blue;
      vertical-align: middle;

      &:hover,
      &:active,
      &:focus {
        color: $link-hover-color;
      }
    }

    & + .header__logo .logo--mini {
      margin-right: 2px;
    }
  }
}

.header__right {
  display: flex;
  align-items: center;
  margin-right: -8px;
}

.header__logo {
  position: relative;
  padding-right: 16px;
  margin-right: 14px;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0px;
    bottom: 0px;
    width: 2px;
    background-color: #b4d0ea;
  }
}

.header__plan {
  margin-left: 8px;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    margin-left: 12px;
  }
}

.header__user {
  display: inline-flex;
  margin-left: 12px;
  cursor: pointer;

  &::not(:last-child) {
    margin-right: 12px;
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--v-primary-base);
  }
}

.header-menu {
  display: flex;
  margin-left: 38px;
  vertical-align: top;
  align-self: stretch;
}
</style>
