import httpClient from '@/api/httpClient';
import i18n from '@/i18n';

const NOMINATIM_API_URL = process.env.VUE_APP_NOMINATIM_URL;
const DEFAULT_FORMAT = 'geojson';

const getNominatinRegionsByQuery = ({ query, options }) =>
  httpClient
    .get(
      `${NOMINATIM_API_URL}search/?q=${query}&format=${DEFAULT_FORMAT}&polygon_geojson=0&addressdetails=0&osm_type=relation&accept-language=${i18n.locale}&extratags=1`,
      {
        signal: options && options.signal,
      },
    )
    .then((response) => response && response.data);

const getNominatimRegionData = ({ osmId, options }) =>
  httpClient
    .get(
      `${NOMINATIM_API_URL}lookup/?osm_ids=${osmId}&format=json&polygon_geojson=0&addressdetails=0&&accept-language=${i18n.locale}`,
      {
        signal: options && options.signal,
      },
    )
    .then((response) => response && response.data);

const getNominatimRegionGeometry = ({ osmId, options }) =>
  httpClient
    .get(`${NOMINATIM_API_URL}lookup/?osm_ids=${osmId}&format=json&polygon_geojson=1&addressdetails=0`, {
      signal: options && options.signal,
    })
    .then((response) => (response && response.data.length > 0 ? response.data[0].geojson : null));

export { getNominatimRegionData, getNominatimRegionGeometry, getNominatinRegionsByQuery };
