import BaseProductService from '@/services/product/BaseProductService';
import i18n from '@/i18n';
import { createSummaryStr, createSummaryStrDetailed } from '@/utils/productUtils/';
import { getFormatName } from '@/utils/formatUtils';

const SAT_IMAGES = [{ name: 'satZ13', format: 'png' }];

class SatProductService extends BaseProductService {
  getSummary({ options }) {
    return createSummaryStr({
      id: this.id,
      attrs: [getFormatName(options.format), `z${options.zoom_level}`],
      layerNumber: options.layers.length,
    });
  }

  getSummaryDetailed(regionName, { options }) {
    return createSummaryStrDetailed({
      regionName,
      id: this.id,
      attrs: [
        [i18n.t('product.zoom_level.label'), i18n.t(`product.zoom_level.value_z${options.zoom_level}`)],
        [i18n.t('product.format'), getFormatName(options.format)],
      ],
      layerNumber: options.layers.length,
    });
  }
}

export { SatProductService, SAT_IMAGES };
