<template>
  <v-tabs
    :class="[
      'ng-tabs',
      `ng-tabs--${view}`,
      { ...sizeClasses, 'ng-tabs--outlined': outlined },
    ]"
    v-bind="$attrs"
    :value="innerValue"
    @change="onChange"
    :background-color="
      view === 'pills' ? 'transparent' : $attrs['background-color']
    "
  >
    <v-tab
      v-for="item in items"
      class="ng-tabs__item"
      :class="item.value === Number(innerValue) ? tabClassActive : tabClass"
      :ripple="item.rippleColor ? { class: `${item.rippleColor}--text` } : true"
      :key="item.key"
      :to="item.to"
      :tab-value="item.value"
      :href="!item.to ? item.href : null"
    >
      <div class="ng-tabs__content">
        <slot
          v-if="$slots[`tab.${item.key}`] || $scopedSlots[`tab.${item.key}`]"
          :name="`tab.${item.key}`"
          :item="item"
        >
        </slot>
        <template v-else>
          <v-icon
            :class="`mr-1 ml-n1 ${iconBreakpointClass}`"
            v-if="item.icon"
            >{{ item.icon }}</v-icon
          >
          <span class="ng-tabs__content-text">
            {{ itemText }}
            {{ item[itemText || 'text'] }}
          </span>
        </template>
      </div>
    </v-tab>
  </v-tabs>
</template>

<script>
const BREAKPOINTS = ['sm', 'md', 'lg'];

export default {
  name: 'NgTabs',
  props: {
    items: { type: Array },
    value: { type: [String, Number] },
    itemText: {
      type: String,
    },
    view: {
      type: String,
      default: 'default', // pills, default
    },
    tabClass: {
      type: String,
      default: '',
    },

    tabClassActive: {
      type: String,
      default: '',
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    iconBreakpoint: {
      type: [String, Boolean],
      validator: (val) =>
        BREAKPOINTS.indexOf(val) !== -1 || val === undefined || val === false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue() {
      return this.value;
    },
    sizeClasses() {
      return {
        'ng-size--x-small': this.xSmall,
      };
    },
    iconBreakpointClass() {
      if (!this.iconBreakpoint) return '';
      const breakpointIndex = BREAKPOINTS.indexOf(this.iconBreakpoint);
      const breakpointClass =
        breakpointIndex === 0
          ? 'hidden-xs-only'
          : `hidden-${BREAKPOINTS[breakpointIndex - 1]}-and-down`;
      return breakpointClass;
    },
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    onChange(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.ng-tabs {
  font-family: $heading-font-family;

  &__item {
    border-bottom: 0;
  }

  &__content {
    position: relative;
  }

  &::v-deep .v-slide-group__next,
  &::v-deep .v-slide-group__prev {
    min-width: 24px;
    flex-basis: 24px;
  }

  &--pills {
    &.v-tabs::v-deep {
      .v-tabs-slider-wrapper {
        display: none;
      }

      & > .v-tabs-bar {
        background-color: transparent;
      }

      .ng-tabs__item {
        margin-right: 8px;
        border-radius: $border-radius-root;
        overflow: hidden;
      }
    }
    &.ng-tabs--outlined {
      .ng-tabs__item {
        border: 1px solid $border-color;
      }
    }

    &.ng-size--x-small {
      .v-tabs-bar {
        height: 32px !important;
      }

      .ng-tabs__item {
        text-transform: none;
        letter-spacing: 0;
        font-size: 14px;
        font-weight: 400;
        margin-right: 0;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 0;
        min-width: 0;

        &:nth-child(2) {
          border-top-left-radius: $border-radius-root;
          border-bottom-left-radius: $border-radius-root;
        }
        &:nth-last-child(1) {
          border-top-right-radius: $border-radius-root;
          border-bottom-right-radius: $border-radius-root;
        }
      }

      &.ng-tabs--outlined .ng-tabs__item {
        &:nth-child(2) {
          border-right: 0;
        }
        &:nth-last-child(1) {
          border-left: 0;
        }
      }
    }
  }
}
</style>
