import i18n from '@/i18n';
import httpClient from '@/api/httpClient';

const fetchCountries = () => httpClient.get(`api/regions/countries/?lang=${i18n.locale}`).then((resp) => resp.data);

const fetchSubdivisions = (countryCode) =>
  httpClient
    .get(`api/regions/by_country/${countryCode}/?region_type=region&lang=${i18n.locale}`)
    .then((resp) => resp.data);

const fetchCities = (countryCode) =>
  httpClient
    .get(`api/regions/by_country/${countryCode}/?region_type=city&lang=${i18n.locale}`)
    .then((resp) => resp.data);

const getDefaultFilterCountry = () => (i18n.locale === 'ru' ? 'RU' : 'US');

export default {
  fetchCountries,
  getDefaultFilterCountry,
  fetchSubdivisions,
  fetchCities,
};
