import i18n from '@/i18n';
import BaseProductService from '@/services/product/BaseProductService';
import { createSummaryStr, createSummaryStrDetailed } from '@/utils/productUtils/';
import { getFormatName } from '@/utils/formatUtils';

const DEM_IMAGES = [{ name: 'dem', format: 'png' }];

const isDemWithVectorLayers = (layers) => layers.includes('contours');
const isDemWithRasterLayers = (layers) => layers.includes('dem') || layers.includes('hillshade');

class DemProductService extends BaseProductService {
  getSummary({ options }) {
    const attrs = [];
    if (isDemWithVectorLayers(options.layers)) {
      attrs.push(getFormatName(options.format_vector));
    }
    if (isDemWithRasterLayers(options.layers)) {
      attrs.push(getFormatName(options.format_raster));
    }
    return createSummaryStr({
      id: this.id,
      attrs,
      layerNumber: options.layers.length,
    });
  }

  getSummaryDetailed(regionName, { options }) {
    const attrs = [];
    if (isDemWithVectorLayers(options.layers)) {
      attrs.push([i18n.t('product.contourlines_step'), options.contourlines_step]);
      attrs.push([i18n.t('product.format_vector'), getFormatName(options.format_vector)]);
    }
    if (isDemWithRasterLayers(options.layers)) {
      attrs.push([i18n.t('product.format_raster'), getFormatName(options.format_raster)]);
    }
    return createSummaryStrDetailed({
      regionName,
      id: this.id,
      attrs,
      layerNumber: options.layers.length,
    });
  }

  getItemCode({ region, options }) {
    const formats = [];
    if (isDemWithVectorLayers(options.layers)) {
      formats.push(options.format_vector);
    }
    if (isDemWithRasterLayers(options.layers)) {
      formats.push(options.format_raster);
    }
    return `${region.code}_${formats.join('_')}_${this.id}`;
  }
}

export { DemProductService, DEM_IMAGES };
