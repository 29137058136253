const convertAxiosErrorToAppError = ({ axiosError, locale }) => {
  const { config, response, message } = axiosError;
  let errorMessage = 'An unknown error occurred';
  if (response) {
    errorMessage =
      response.data && response.data.description
        ? response.data.description[locale] || response.data.description
        : response.statusText;
  } else if (message) {
    errorMessage = message;
  }

  const errorStatus = response && response.status;
  const { noErrorNotification = false } = config;

  let errorId;
  if (response) {
    errorId = response.data && response.data.error_id;
  } else if (config) {
    errorId = config.errorId;
  }

  const errorMeta = response && response.data && response.data.meta;

  return {
    errorId,
    errorMessage,
    errorStatus,
    noErrorNotification,
    errorMeta,
  };
};

export { convertAxiosErrorToAppError };
