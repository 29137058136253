import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import minifyCssString from 'minify-css-string';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: '#0a6feb',
        // primary: '#0a56ec',
        primary: '#0070c5',
        accent: '#00b77e',
        secondary: '#e5eef7',
        info: '#42a8f6',
        warning: '#ffc107',
        error: '#ff5252',
        success: '#4caf50',
        'grey-bg': '#F2F4F5',
        grey: '#B3B9BE',
        'grey-dark': '#808A93',
        'grey-extra-dark': '#222b32',
      },
    },
    options: {
      customProperties: true,
      variations: false,
      minifyTheme: minifyCssString,
    },
  },
});
