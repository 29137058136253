function isAbsoluteUrl(url) {
  const absoluteUrlRegex = /^(?:[a-z]+:)?\/\//i; // Matches protocols and "//" for absolute URLs

  return absoluteUrlRegex.test(url);
}

const addTimestampToUrl = (url, timestamp = process.env.BUILD_TIMESTAMP, origin = window.location.origin) => {
  const isAbsolute = isAbsoluteUrl(url);
  const urlObject = isAbsolute ? new URL(url) : new URL(url, origin);
  if (timestamp) urlObject.searchParams.set('t', timestamp);
  return isAbsolute ? urlObject : urlObject.href.replace(urlObject.origin, '');
};

export { addTimestampToUrl };
