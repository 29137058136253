<template>
  <span :class="`app-user app-user--${view}`">
    <template v-if="view !== 'text'">
      <img class="app-user__pic" :src="baseUser.userpicUrl" alt="user" />
    </template>
    <template v-if="view !== 'pic'">
      <span class="app-user__name d-none d-md-inline-block">{{
        baseUser.username
      }}</span>
    </template>
  </span>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AppUser',
  props: {
    view: {
      type: String,
      default: 'full',
      validator(value) {
        return ['full', 'pic', 'text'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    ...mapState(['baseUser']),
  },
};
</script>

<style lang="scss" scoped>
.app-user {
  display: inline-flex;
  align-items: center;

  &__pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  &--full {
    &::v-deep .app-user__name {
      margin-left: 8px;
    }
  }
}
</style>
