const areRoutesHaveSameRegions = (routeA, routeB) => {
  if (routeA.params.regionCode !== routeB.params.regionCode) return false;
  return routeA.params.regionCode === 'custom'
    ? routeA.query.osmId === routeB.query.osmId && routeA.query.regionCode === routeB.query.regionCode
    : routeA.params.regionCode === routeB.params.regionCode;
};

const clearRegionQueriesFromRoute = (currentRoute, router) => {
  if (currentRoute.query.osmId || currentRoute.query.regionCode) {
    const newRouteQuery = { ...currentRoute.query };
    delete newRouteQuery.osmId;
    delete newRouteQuery.regionCode;
    router.replace({
      ...currentRoute,
      query: newRouteQuery,
    });
  }
};

const setNominatimOsmIdToRouteQuery = (route, osmId, router) => {
  if (Number(route.query.osmId) !== osmId)
    router.replace({
      ...route,
      query: {
        ...route.query,
        osmId,
      },
    });
};

const setRegionCodeToRouteQuery = (route, regionCode, router) => {
  if (route.query.regionCode !== regionCode)
    router.replace({
      ...route,
      query: {
        ...route.query,
        regionCode,
      },
    });
};

export {
  areRoutesHaveSameRegions,
  clearRegionQueriesFromRoute,
  setNominatimOsmIdToRouteQuery,
  setRegionCodeToRouteQuery,
};
