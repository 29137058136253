export const getDefaultInputAttrs = (attrs) => {
  return {
    outlined: !('filled' in attrs) || !attrs.floatedLabel,
    dense: true,
    singleLine: !attrs.floatedLabel,
    hideDetails: 'auto',
    backgroundColor: attrs.filled ? null : '#fff',
  };
};

export const prepareInputAttrs = (attrs, defaultAttrs) => {
  let attrsLocal = attrs;
  if (!attrs.floatedLabel) {
    // remove label to show placeholder
    // eslint-disable-next-line no-unused-vars
    const { label, ...noLabelAttrs } = attrs;
    attrsLocal = noLabelAttrs;
  }
  return {
    ...defaultAttrs,
    ...attrsLocal,
  };
};
