export default class SplitTest {
  case;

  constructor() {
    if (!SplitTest.getSavedCase()) {
      this.case = Math.random() < 0.5 ? 'A' : 'B';
      localStorage.setItem('test_case', this.case);
    } else {
      this.case = SplitTest.getSavedCase();
    }
    document.querySelector('html').classList.add(this.case);
  }

  static getSavedCase() {
    return localStorage.getItem('test_case');
  }
}
