const regions = {
  namespaced: true,
  state: () => ({
    currentRegion: undefined,
    currentRegionCode: undefined,
    currentRegionGeometry: undefined,
  }),
  getters: {
    currentRegionName: (state) => state.currentRegion && state.currentRegion.name,
  },
  mutations: {
    SET_CURRENT_REGION(state, region) {
      state.currentRegion = region; // {code, geometry}
    },
    SET_CURRENT_REGION_CODE(state, code) {
      state.currentRegionCode = code;
    },
    SET_CURRENT_REGION_GEOMETRY(state, geometry) {
      state.currentRegionGeometry = geometry;
    },
  },
  actions: {},
};

export default regions;
