import i18n from '@/i18n';
import httpClient from '@/api/httpClient';
import * as productApi from '@/api/productApi';
import BaseProductService from './BaseProductService';
import { OsmProductService } from './osmProductService';
import { DemProductService } from './demProductService';
import { SatProductService } from './satProductService';

const productAbortControllers = new Map();

const services = {
  base: new OsmProductService('base'),
  dem: new DemProductService('dem'),
  sat: new SatProductService('sat'),
  gkh: new BaseProductService('gkh'),
  msbld: new BaseProductService('msbld'),
  msrd: new BaseProductService('msrd'),
  overture: new BaseProductService('overture'),
  oopt: new BaseProductService('oopt'),
  rnlic: new BaseProductService('rnlic'),
  heritage: new BaseProductService('heritage'),
};

const getService = (id) => {
  if (services[id]) return services[id];
  throw new Error(`Service for product with id ${id} does not exist`);
};

const lastTwoValidProductData = [];

const getProductPrice = async (productOptions, options) => {
  if (productAbortControllers.has('price')) productAbortControllers.get('price').abort();

  const priceAbortController = new AbortController();
  productAbortControllers.set('price', priceAbortController);
  try {
    const priceObject = await productApi.getProductPrice({
      product: { ...productOptions, id: productOptions.serverId },
      locale: i18n.locale,
      options: { ...options, signal: priceAbortController.signal },
    });
    return priceObject;
  } finally {
    productAbortControllers.delete('price');
  }
};

const validateProductData = (productOptions) => {
  const { layers } = productOptions.options;
  const { geometry } = productOptions.region;
  const errors = [];
  if (layers.length === 0) errors.push(i18n.t('product.errors.specifyLayers'));
  if (productOptions.region.code === 'custom' && !geometry) errors.push(i18n.t('product.errors.specifyArea'));
  if (productOptions.options.format === 'pdf' && !productOptions.options.pdfOptions)
    errors.push(i18n.t('product.errors.specifyPdf'));
  if (productOptions.id === 'dem' && !productOptions.options.contourlines_step)
    errors.push(i18n.t('product.errors.specifyContourLines')); // TODO: show error in UI

  return {
    isValid: !errors.length,
    errors,
  };
};

const getContourLineSteps = (regionCode) => {
  if (productAbortControllers.has('contourLineSteps')) productAbortControllers.get('contourLineSteps').abort();

  const contourLineStepsAbortController = new AbortController();
  productAbortControllers.set('contourLineSteps', contourLineStepsAbortController);

  return httpClient
    .get(`/api/product/dem/countourlines_steps/?region=${regionCode}`, {
      signal: contourLineStepsAbortController.signal,
    })
    .then((resp) => {
      productAbortControllers.delete('contourLineSteps');
      return resp?.data ? resp.data.data : [];
    });
};

const getSummary = (productOptions) => getService(productOptions.id).getSummary(productOptions);

const getSummaryNoHTML = (productOptions) => getSummary(productOptions).replace(/<[^>]*>?/gm, '');

const getSummaryDetailed = (regionName, productOptions) =>
  getService(productOptions.id).getSummaryDetailed(regionName, productOptions);

const getItemCode = (productOptions) => getService(productOptions.id).getItemCode(productOptions);

const updateLastTwoValidProductData = (newProductData) => {
  lastTwoValidProductData.unshift(newProductData);
  if (lastTwoValidProductData.length > 2) lastTwoValidProductData.pop();
};
const resetLastTwoValidProductData = () => {
  lastTwoValidProductData.length = 0;
};

const isOptionsChangedSignificantly = () => {
  return getService(lastTwoValidProductData[0].id).isOptionsChangedSignificantly(
    lastTwoValidProductData[0],
    lastTwoValidProductData[1],
  );
};

const getProductLayers = ({ id, regionCode }) => {
  if (productAbortControllers.has('layers')) productAbortControllers.get('layers').abort();

  const layerAbortController = new AbortController();
  productAbortControllers.set('layers', layerAbortController);

  return httpClient
    .get(`/api/product/${id}/layers/?region=${regionCode}&lang=${i18n.locale}`, {
      noErrorNotification: true,
      signal: layerAbortController.signal,
    })
    .then((response) => {
      productAbortControllers.delete('layers');
      const layersData = response.data.data
        .filter((layer) => layer.featureCount === undefined || layer.featureCount > 0)
        .map((layer) => ({ ...layer }));
      return layersData;
    });
};

const cancelAllProductRequests = () => {
  productAbortControllers.forEach((controller) => controller.abort());
  productAbortControllers.clear();
};

export default {
  getService,
  getProductPrice,
  validateProductData,
  getSummary,
  getSummaryNoHTML,
  getSummaryDetailed,
  getItemCode,
  getProductLayers,
  getContourLineSteps,
  isOptionsChangedSignificantly,
  updateLastTwoValidProductData,
  resetLastTwoValidProductData,
  cancelAllProductRequests,
};
