import i18n from '@/i18n';
import { createSummaryStr, createSummaryStrDetailed, getProductImages } from '@/utils/productUtils/';
import { getFormatName } from '@/utils/formatUtils';
import { getProductSettingsByKeys } from '@/services/product/settings/productSettings';

export default class BaseProductService {
  constructor(id) {
    this.id = id;
  }

  getSettings(regionCode) {
    const settings = getProductSettingsByKeys({ keys: [this.id], i18n })[0];
    if (settings) {
      const { productionTime, productOptions, images } = settings;
      return {
        ...settings,
        productOptions: typeof productOptions === 'function' ? productOptions(regionCode) : productOptions,
        productionTime: typeof productionTime === 'function' ? productionTime(regionCode) : productionTime,
        images: images && images.length > 0 && getProductImages(images, this.id),
      };
    }
    throw new Error(`${this.id} settings are not defined`);
  }

  getSummary({ options }) {
    return createSummaryStr({
      id: this.id,
      attrs: [getFormatName(options.format)],
      layerNumber: options.layers.length,
    });
  }

  getSummaryDetailed(regionName, { options }) {
    return createSummaryStrDetailed({
      regionName,
      id: this.id,
      attrs: [[i18n.t('product.format'), getFormatName(options.format)]],
      layerNumber: options.layers.length,
    });
  }

  getItemCode({ region, options }) {
    return `${region.code}_${options.format}_${this.id}`;
  }

  // eslint-disable-next-line class-methods-use-this
  isOptionsChangedSignificantly() {
    return true;
  }
}
