const createPreviouslySelectedRegions = (items) => items.map((item) => ({ ...item, isHistory: true }));

const areRegionsEqual = (regionA, regionB) => {
  if (regionA && regionB && regionA.source !== regionB.source) return false;
  if (regionA.source === 'catalog' && regionA.regionData.code === regionB.regionData.code) return true;
  if (regionA.source === 'nominatim' && regionA.regionData.osmId === regionB.regionData.osmId) return true;
  return false;
};

const convertNominatimLookupItemToSearchResult = ({ osm_id, osm_type, display_name }) => ({
  source: 'nominatim',
  regionData: { osmId: `${osm_type[0].toUpperCase()}${osm_id}`, name: display_name },
});

const convertRegionDataToSearchResult = (regionData) => {
  // add invisible symbols
  // because of Vuetify v-autocomplete shows only one of several items
  // with equal name (item-text). Text with query 'tbilisi', 'moscow'
  let invisibleSuffix = '';
  if (regionData.code.includes('-') && regionData.code.includes('CITY')) {
    invisibleSuffix = `\u200B`;
  } else if (regionData.code.includes('-')) {
    invisibleSuffix = `\u200C`;
  } else {
    invisibleSuffix = `\u200D`;
  }

  return {
    source: 'catalog',
    regionData: {
      ...regionData,
      name: regionData.code.includes('-')
        ? `${regionData.name}, ${regionData.countryName}${invisibleSuffix}`
        : regionData.name,
    },
  };
};
const convertNominatimSearchItemToSearchResult = ({ osm_id, osm_type, display_name }) => ({
  source: 'nominatim',
  regionData: {
    osmId: `${osm_type[0].toUpperCase()}${osm_id}`,
    name: display_name,
  },
});

export {
  createPreviouslySelectedRegions,
  areRegionsEqual,
  convertNominatimLookupItemToSearchResult,
  convertRegionDataToSearchResult,
  convertNominatimSearchItemToSearchResult,
};
