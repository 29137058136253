import { cloneDeep, debounce } from 'lodash-es';
import { notice } from '@nextgis/vuetify-notice';
import i18n from '@/i18n';
import productService from '@/services/product/productService';
import { convertAxiosErrorToAppError } from '@/utils/errorUtils';

import { ERRORS_TO_SAVE_TO_STORE } from '@/constants/product/productConstants';

const debouncedPriceRequest = debounce(async ({ getters, commit }) => {
  const { productDataFormatted } = getters;
  if (productDataFormatted) {
    try {
      const productPrice = await productService.getProductPrice(
        {
          ...productDataFormatted,
        },
        { noErrorNotification: true },
      );
      commit('SET_PRICE', productPrice?.originPrice);
      commit('SET_IS_PRICE_LOADING', false);
    } catch (error) {
      const { errorMessage, errorId, errorMeta } = convertAxiosErrorToAppError({
        axiosError: error,
        locale: i18n.locale,
      });

      const shouldSaveErrorsToStore = Array.isArray(ERRORS_TO_SAVE_TO_STORE)
        ? ERRORS_TO_SAVE_TO_STORE.includes(errorId)
        : !!ERRORS_TO_SAVE_TO_STORE;

      if (shouldSaveErrorsToStore) {
        commit(
          'app/ADD_API_ERROR',
          {
            id: errorId,
            message: errorMessage,
            meta: {
              productName: i18n.t(`product.${productDataFormatted.id}.title`),
              ...errorMeta,
            },
          },
          { root: true },
        );
      } else {
        notice(errorMessage, {
          color: 'error',
          timeout: 5000,
          icon: 'error',
        });
      }
    }
  }
}, 500);

const product = {
  namespaced: true,
  state: () => ({
    id: null,
    serverId: null,
    productOptions: null,
    defaultLayers: [], // initial value of layers
    layers: [], // layers selected by user
    price: null,
    isPriceLoading: false,
    url: undefined,
  }),
  getters: {
    productDataFormatted(state, getters, rootState) {
      const { currentRegion, currentRegionCode: code, currentRegionGeometry } = rootState.regions;
      if (state.productOptions && currentRegion && code) {
        const options = {
          ...state.productOptions,
          ...state.productOptions.formModel,
          layers: state.layers,
        };
        delete options.formModel;

        const { country, name } = currentRegion;
        const wkt = currentRegionGeometry && currentRegionGeometry.wkt;
        const region = { code, geometry: wkt, country, name };

        return {
          id: state.id,
          serverId: state.serverId,
          lang: i18n.locale,
          region,
          options,
          url: state.url,
        };
      }
      return null;
    },
  },
  mutations: {
    SET_PRODUCT_ID(state, id) {
      state.id = id;
    },
    SET_PRODUCT_SERVER_ID(state, serverId) {
      state.serverId = serverId;
    },
    SET_PRODUCT_OPTIONS(state, productOptions) {
      state.productOptions = cloneDeep(productOptions);
    },
    SET_PRODUCT_LAYERS(state, layers) {
      state.layers = layers;
    },
    SET_DEFAULT_LAYERS(state, layers) {
      state.defaultLayers = layers;
    },
    SET_PRICE(state, price) {
      state.price = price;
    },
    SET_IS_PRICE_LOADING(state, isPriceLoading) {
      state.isPriceLoading = isPriceLoading;
    },
    SET_PRODUCT_URL(state, url) {
      state.url = url;
    },
  },
  actions: {
    async loadPrice({ commit, dispatch }) {
      commit('SET_IS_PRICE_LOADING', true);
      commit('SET_PRICE', null);
      dispatch('cancelDebouncedPriceRequest');
      dispatch('sendPriceRequest');
    },
    sendPriceRequest: debouncedPriceRequest,
    cancelDebouncedPriceRequest: debouncedPriceRequest.cancel,
  },
};

export default product;
