import { isEqual } from 'lodash-es';
import BaseProductService from '@/services/product/BaseProductService';
import i18n from '@/i18n';
import { createSummaryStr, createSummaryStrDetailed } from '@/utils/productUtils/';
import { getFormatName } from '@/utils/formatUtils';

const LAYER_NUMBER_FOR_DISCOUNT = 5;

const OSM_IMAGES = [
  { name: 'baseAtd', format: 'png' },
  { name: 'baseRoads', format: 'png' },
  { name: 'baseHist', format: 'gif' },
  { name: 'baseQgis', format: 'png' },
  { name: 'baseMapinfo', format: 'png' },
  { name: 'baseArcgis', format: 'png' },
];

const OSM_NO_LAYERS_FORMATS = ['pbf-osm', 'xml-osm'];

class OsmProductService extends BaseProductService {
  getSummary({ options }) {
    return createSummaryStr({
      id: this.id,
      attrs: [getFormatName(options.format)],
      layerNumber: OSM_NO_LAYERS_FORMATS.includes(options.format) ? null : options.layers.length,
    });
  }

  getSummaryDetailed(regionName, { options }) {
    return createSummaryStrDetailed({
      regionName,
      id: this.id,
      attrs: [[i18n.t('product.format'), getFormatName(options.format)]],
      layerNumber: OSM_NO_LAYERS_FORMATS.includes(options.format) ? null : options.layers.length,
    });
  }

  isOptionsChangedSignificantly(newOptions, oldOptions) {
    if (!oldOptions) return true;

    const { options: newProductOptions, region: newRegion, lang: newLang } = newOptions;
    const { options: oldProductOptions, region: oldRegion, lang: oldLang } = oldOptions;
    const { layers: newLayers, ...newOptionsWithoutLayers } = newProductOptions;
    const { layers: oldLayers, ...oldOptionsWithoutLayers } = oldProductOptions;
    const { geometry: newGeometry } = newRegion;
    const { geometry: oldGeometry } = oldRegion;

    if (newLang !== oldLang) return true;
    if (newGeometry !== oldGeometry) return true;
    if (!isEqual(newOptionsWithoutLayers, oldOptionsWithoutLayers)) return true;

    const isDiscountForLayersChanged =
      (newLayers.length <= LAYER_NUMBER_FOR_DISCOUNT && oldLayers.length > LAYER_NUMBER_FOR_DISCOUNT) ||
      (newLayers.length > LAYER_NUMBER_FOR_DISCOUNT && oldLayers.length <= LAYER_NUMBER_FOR_DISCOUNT);
    if (isDiscountForLayersChanged) return true;

    return false;
  }
}

export { OSM_NO_LAYERS_FORMATS, OSM_IMAGES, OsmProductService };
