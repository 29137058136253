import httpClient from '@/api/httpClient';

const getRegionsByQuery = ({ query, lang, count, options }) =>
  httpClient
    .get(`/api/regions/by_query/?q=${query}&lang=${lang}&count=${count}`, { signal: options && options.signal })
    .then((response) => response && response.data);

const getRegionData = ({ regionCode, lang, options }) =>
  httpClient
    .get(`/api/regions/${regionCode}/info/?lang=${lang}`, { signal: options && options.signal })
    .then((resp) => resp && resp.data);

const getRegionGeometry = ({ regionCode, decimals = 5, options }) =>
  httpClient
    .get(`/api/regions/${regionCode}/geometry/?decimals=${decimals}`, { signal: options && options.signal })
    .then((resp) => resp && resp.data);

export { getRegionsByQuery, getRegionData, getRegionGeometry };
